


.services__container {

    /* border: 2px solid red; */
    /* display: flex; */
    /* flex-direction: column; */
    display:grid;
    grid-template-columns: repeat(1,1fr);
    height: auto;
    gap: 2rem;
     width: 80%;
     /* margin: auto; */

    font-family: Inter, sans-serif;

}





@media screen and (min-width:600px){
     
    .services__container{
        flex-direction: row;
        grid-template-columns: repeat(2,1fr);
        gap: 0rem;
        /* width: 100%; */
       
    }

   
    
}

@media screen and (max-width:600px ){
  
.services__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin: auto;
}
}

@media screen and (min-width:807px){
     
    .services__container{
        flex-direction: row;
        grid-template-columns: repeat(2,1fr);
        gap: 0rem;
        width: 100%;
        justify-items: center;
       
    }
    
    
}

@media screen and (min-width:1113px){
     
    .services__container{
        flex-direction: row;
        grid-template-columns: repeat(3,1fr);
        gap: 0rem;
        width: 100%;
       padding: 1rem;
    }
    
    
}


