*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}



.content__section{
    padding-top: 60px;
}

.mark{
    border: 1px solid red;
}

body{
    overflow-x: hidden;
    background-color: #fff;
}