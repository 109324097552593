


video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    overflow:hidden;
  }
  
  .hero-container {
    height: 85vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: cover;
  }

  .hero__details{
   display: flex;
   flex-direction: column;
  }


  @media screen and (max-width: 500px){

    .hero__details  {
      margin-top:3rem;
    }
   
   

  }



@media screen and (min-width: 500px){
 
  .hero-container {
    height: 100vh;
  }
}

  .hero__details h1{
    z-index: 1;
  }
  


  

  .hero-btns {
    margin-top: 32px;
    z-index: 1;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
.overscreen{
  position: absolute;
  opacity: 0;
  display: none;
  width: 100%;
  height: 100%;
  z-index: 0;
  background:linear-gradient(0deg, rgba(54, 53, 54, 0.3),rgba(4, 11, 26, 0.3) );
}
  
  @media screen and (max-width: 768px) {
  
  
    
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }
  



   .main__text {
    color: #fff;
    font-family: Inter, sans-serif;
    font-size: 3.375rem;
    font-style: normal;
    font-weight: 700;
    margin: 0 auto;
  }


  .main__text {
    max-width: 932px;
}


  @media screen and (max-width: 1024px)
{
 .main__text {
    max-width: 794px;
} 
}



@media screen and (max-width: 720px)
{
 .main__text {
    font-size: 38px;
    line-height: 42px;
} 
}

@media screen and (max-width: 1024px)
{
  .main__text {
    font-size: 46px;
    line-height: 51px;
}
}



@media screen and (max-width: 565px){

  .main__text {
  
    font-size: 2.175rem;
  
  }

}

@media screen and (max-width: 500px){

  .main__text {
  
    font-size: 35px;
    line-height: 37px;
  
  }

  .overscreen{
    display:block;
    opacity:1;
  }

}

.hero__details{
  display: flex;
  flex-direction: column;
  gap: 16px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  inset: 0px;
}