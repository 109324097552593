.service__container {
    border: 2px solid #eee;
    
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: all 0.2s ease-in;
    max-width:368px;
}



.service__container:hover {
    border-bottom: 3px solid #2d69fd;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom:.5rem;
}

.service__line-container{
    display: flex;
    align-items: center;
    justify-content:center;
}


.service__details{
    margin:2rem 1rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}

.service__logo-container {
}


.service__links-container {
    display: flex;
    justify-content:center;
    align-items: center;
}


.service__ul {
    list-style: none;
    padding: 0;
    margin-bottom: 2rem;
}

.service__list-item {
    padding: .5rem;
    width: 100%;
    margin: auto;
    border-bottom: .1px solid #eee;
}
.service__link {
    text-decoration: none;
    font-size: 1.2rem;
    color: #111;
  text-transform: uppercase;
}
.service__link:hover {
    text-decoration: none;
    font-size: 1.2rem;
    color: #2d69fd;

}

.service__link-active {
    text-decoration: underline;
    font-size: 1.2rem;
    color: #111;
  text-transform: uppercase;
}


.service__list-item:nth-last-child(1){
    border-bottom: 1px solid transparent;
}

.service__title {
    font-size: 1em;
    font-weight:500;
    display: inline-block;
    text-transform: uppercase;
}
.service__description {
    font-size: 1em;
    font-weight:300;
    display: inline-block;
   
}

.separator {
    width: 100%;
    height:10px;
    border-top: 1px solid gray;
}

.slider__container{
  
    width: 100%;
    height: auto;
}



.service__image{
    width:100%;
    /* height:300px; */
    object-fit: cover;
}

@media screen and (min-width: 800px){

    .service__title {
        font-size: 1.1rem;
        font-weight:500;
        
    }
    .service__description {
        font-size: 1.2rem;
        font-weight:300;
       
    }

    .service__details{
     font-size:2em;
    margin:2rem 1rem 0rem;
    }


    .service__container {

        /* width: 80%; */
  
    }


}